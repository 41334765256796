<template>
  <div v-if="room" :class="spaceClass">
    <section
      class="roomHeader"
      :style="{backgroundColor: room.space.section.color}" >
      <div class="overlay" :style="{backgroundImage: room.experience.image ? 'url(' + encodeURI(room.experience.image.sizes.mobile.url || room.experience.image.sizes.thumbnail.url) + ')' : ''}">
      </div>
      <div class="exit-button mr-2" @click="Exit()">
        <d-icon
          pack="fas"
          icon="arrow-left"  
          />
      </div>
      <h5 class="title pb-2 is-size-5">{{ room.experience.title }}</h5>
    </section>

    <!-- Experience component behaviour -->
    <component :is="GetComponent" :options="room.experience.component[0]" :key="room.experience.id" @hook:mounted="ExperienceComponentMounted" class="main-section controller-component"/>
    <!-- Host control layer (borde en host control) -->
    <div id="hostControlIndicator" :class="{active: hostControl}"></div>

    <!-- Talking points modal -->
    <b-modal v-model="$store.state.talkingPointsShow" class="talking-points" :can-cancel="false">
      <div class="modal-card" style="height:100%; max-width: 100%">
        <header class="modal-card-head has-background-white-bis">
          <p class="modal-card-title is-size-5"> {{ $store.state.talkingPoints.title }}</p>
        </header>
        <section class="modal-card-body has-background-white-ter">
          <p class="mb-3">{{ $t('remote.talkingPoints') }}</p>
          <json-rich-text v-if="$store.state.talkingPointsShow" :text="$store.state.talkingPoints.content" />
        </section>
        <footer class="modal-card-foot is-justify-content-end">
          <b-button
            type="is-primary"
            size="is-small"
            :label="$t('system.close')"
            expanded
            @click="$store.commit('TalkingPointsHide')" />
        </footer>
      </div>
    </b-modal>

    <!-- Go to Menu Confirmation dialog -->
    <b-modal v-model="showDialogGoToMenu" scroll="keep" :can-cancel="false">
      <div class="modal-card">
        <header class="modal-card-head has-background-white-bis">
          <p class="modal-card-title is-size-5">{{$t('system.areYouSure')}}</p>
        </header>
        <section class="modal-card-body has-background-white-ter">
          {{ $t('system.youWillLeave') }}
        </section>
        <footer class="modal-card-foot">
          <b-button
            :label="$t('system.cancel')"
            type="is-primary"
            size="is-small"
            outlined
            expanded
            @click="showDialogGoToMenu = false" />
          <b-button
            :label="$t('system.exit')"
            type="is-primary"
            size="is-small"
            @click="GoToMenu()"
            expanded/>
        </footer>
      </div>
    </b-modal>

  </div>
</template>

<script>
// eslint-disable-next-line
const States = Object.freeze({
  waitingPlayers: 0,
  starting: 1,
  playing: 2,
  finalScore: 3,
});

import JsonRichText from "@/components/JsonRichText";

export default {
  components: {
    JsonRichText,
    // Componentes dinámicos. Acá se listan TODOS, pero sólo se carga
    // el determinado desde servidor en: playlist.experience.controller_component
    queue: () => import("@/components/controllers/PlayerQueue"),
    error: () => import("@/components/controllers/ErrorController"),
    trivia: () => import("@/components/controllers/TriviaController"),
    survey: () => import("@/components/controllers/SurveyController"),
    infoSpot: () => import("@/components/controllers/InfoSpot"),
    videoSelector: () => import("@/components/controllers/VideoSelector"),
    infographic: () => import("@/components/controllers/InfographicController"),
    questionsBox: () => import("@/components/controllers/TextBoxController"),
    external: () => import("@/components/controllers/ExternalExperience.vue"),
    wordcloud: () => import("@/components/controllers/TextBoxController"),
    defenseGame: () => import("@/components/controllers/DefenseGameController"),
    raffle: () => import("@/components/controllers/RaffleController"),

  },
  props: {},
  data() {
    return {
      serverState: 0,
      matchFinished: false,
      hostControl: false,
      hostControlReceived: false,
      showDialogGoToMenu: false
    };
  },
  computed: {
    spaceClass(){
      return `space-${this.room.space.alphanumeric?.toLowerCase()}`
    },
    isHost() {
      return this.$store.getters["user/hasAdminAuthority"]
    },
    GetComponent() {
      if (this.$store.state.user.queue) {
        return "queue";
      }
      if (this.room.experience.component.length == 0) {
        return "error";
      }
      const components = Object.keys(this.$options.components);
      const blockType = this.room.experience.component[0].blockType;
      if (!components.includes(blockType)) {
        console.warn(`El componente ${blockType} no está registrado`)
        return "error";
      }
      return this.room.experience.component[0].blockType;
    },
    room() {
      return this.$store.getters.playlist.find(
        (x) => x.space.slug == this.$route.params.slug
      );
    },
  },
  watch: {
  },
  sockets: {
    hostControlState(data){
      console.log("🎭 Host control changed", data.state)
      this.hostControl = data.state;

      if(this.isHost && !this.hostControlReceived && this.$store.state.admin.defaultHostControl){
        this.$socket.client.emit("changeHostControl", {
          state: true, 
          dbid: this.$store.state.user.profile.id,
        })
        this.hostControlReceived = true
      }
    },
    spaceState(data) {
      console.log("💡 Space state changed", data.state);
      this.serverState = data.state;
    },
    matchFinished() {
      this.matchFinished = true;
    }
  },
  methods: {
    ToggleHostControl(){
      // console.log("Change host control state")
      this.hostControl = !this.hostControl
      this.$socket.client.emit("changeHostControl", {
        state: this.hostControl, 
        dbid: this.$store.state.user.profile.id,
      })
    },
    Exit(){
      if(this.room.experience.component[0].queue && this.serverState < 3){
        this.showDialogGoToMenu = true
      } else {
        this.$router.push("/")
      }
    },
    GoToMenu(){
      this.$router.push("/")
    },
    ExperienceComponentMounted(){
      this.$store.commit("StopLoading")
      this.$socket.client.emit(
        "player:JoinSpace",
        { spaceSlug: this.$route.params.slug },
        (response) => {
          if (response.status) {
            // next();
            console.log("Server allowed join")
          } else {
            console.warn("Server did not allow joining");
            // return false;
          }
        }
      );
    }
  },
  mounted() {
    try {
      this.$store.dispatch("enableNoSleep")
    } catch {
      console.log("no sleep did not work because no user input")
      document.addEventListener('click', function enableNoSleep() {
        document.removeEventListener('click', enableNoSleep, false);
        this.$store.dispatch("enableNoSleep")
      }, false);
    }
  },
};
</script>
